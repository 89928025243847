import React, { useState } from 'react';
import './App.css';
import PartnerLogosSection from './components/PartnerLogos';

function App() {
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState('');

  const handleButtonClick = () => {
    setShowForm(true);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const googleFormActionUrl = "https://docs.google.com/forms/u/0/d/e/1FAIpQLSeEE7fqlczEkEqvwWmPEHLSfoTue9dxt9QlJBH_kMhclXPDYg/formResponse";
    const formData = new FormData();
    formData.append('entry.340943594', email);

    fetch(googleFormActionUrl, {
      method: 'POST',
      body: formData,
      mode: 'no-cors',
    })
    .then(() => {
      alert(`Email submitted: ${email}`);
      setEmail('');
      setShowForm(false);
    })
    .catch(() => {
      alert('Error submitting form. Please try again.');
    });
  };

  return (
    <div className="App">
      <header className="header">
        <div className="logo">CartLink</div>
      </header>

      <main className="content">
        <section className="hero">
          <h1>The Digital Shopping Cart.</h1>
          <p>One click. Every ingredient.</p>
          <button className="cta-btn" onClick={handleButtonClick}>Creator Waiting List</button>
        </section>

        <section className="loom-demo">
          <div className="iframe-container">
            <iframe
              src="https://www.loom.com/embed/970a3946a5524c9592f14f29ebfc8bf8?sid=676df602-c0ef-4190-93ca-85cc8c5a5266&autoplay=1"
              frameBorder="0"
              allowFullScreen
              title="Loom Video Demo"
            ></iframe>
          </div>
        </section>

        <section className="partner-logos">
          <PartnerLogosSection />
        </section>
      </main>

      {showForm && (
        <div className="form-overlay">
          <form className="email-form" onSubmit={handleFormSubmit}>
            <h2>Join the Waiting List</h2>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default App;

