import React from 'react';
import './PartnerLogos.css';

// Dynamically import logos from the 'logos' folder
const logoContext = require.context('../assets/logos', false, /\.(png|jpe?g|svg)$/);
const logos = logoContext.keys().map(logoContext); // Import all logos

const PartnerLogosSection = () => {
  // Duplicate the logos array to show each logo twice
  const doubledLogos = [...logos, ...logos];

  return (
    <section className="partner-logos-container">
      <div className="logos-wrapper">
        {doubledLogos.map((logo, index) => (
          <div className="logo-item" key={index}>
            <img src={logo} alt={`Partner Logo ${index + 1}`} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default PartnerLogosSection;